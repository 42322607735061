import React from 'react';
import './Home.css';  // Ensure the CSS file is linked
import pavlos from './image-pavlos.jpg'; // Ensure the image file is linked
import {Link} from 'react-router-dom';

function Home() {
    return (
        <div className="home-container">
            <div className="profile-section">
                <img src={pavlos} alt="Pavlos Constas-Malevanets" className="profile-image"/>
            </div>
            <div className="text-section">
                <h1 className="animated-text">Welcome to My Portfolio</h1>
                <p>I am Pavlos Constas-Malevanets, an incoming Engineering Science student at the University of Toronto. I am very passionate about technology and innovation.</p>
                <Link to="/resume" className="cta-button">View My Resume</Link>
            </div>
        </div>
    );
}

export default Home;
