import React from 'react';
import './Footer.css'; 

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>Contact me at: <a href="mailto:pconstasm@gmail.com">pconstasm@gmail.com</a>
                </p>
                <p>© 2024 Pavlos Constas. All rights reserved.</p>
                <ul className="social-list">
                    <li><a href="https://www.linkedin.com/in/pavlos-constas-malevanets-271b36281/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                    <li><a href="https://github.com/pavlosconstas" target="_blank" rel="noopener noreferrer">GitHub</a></li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;
