import React from 'react';
import './Projects.css';

const Projects = () => {
    return ( 
        <div className="projects">
            <div className="cards">
                <div className="card">
                    <h2>The "Rowelometer"</h2>
                    <p>I propose a robust and inexpensive device
for measuring the speed of a rowing shell and the
stroke rate of the rowers. I used a GPS receiver, an
accelerometer, and a microcontroller to measure the
speed of the shell and the stroke rate of the rowers.
The method behind the technology relies on Kalman
Filtering to combine the GPS and accelerometer
data to produce a more accurate measurement of
the shell’s speed. The method is accurate and have
been thorougly tested by comparing it alongside the
industry standard. I used Altium Designer to design
the PCB and the microcontroller code is written in C.
The total cost of the device is less than $100. Read the write-up for the project <a href="/public/Rowelometer.pdf" target='_blank' rel="noopener noreferrer">here</a>!</p>
                </div>
            </div>
        </div>
     );
}
 
export default Projects;