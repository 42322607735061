import React from 'react';
import './Resume.css';  // Make sure this CSS file reflects your website's styling

const Resume = () => {
    return (
        <div className='resume'>
            <section id="resume">
                <header>
                    <h1>Engineering Science Student at the University of Toronto | Class of 2028</h1>
                </header>
                <article>
                    <h2>Education</h2>
                    <p>University of Toronto, BASc in Engineering Science, Expected 2028</p>
                </article>
                <article>
                    <h2>Work Experience</h2>
                    <p><strong>Assistant Analyst</strong>, Advanced Mineral Technological Laboratory, London, Ontario, CA</p>
                    <ul>
                        <li>Responsible for tasks assigned under the director of AMTEL.</li>
                        <li>Created software for mineral analysis using CNN, data processing, and computer vision algorithms.</li>
                        <li>Developed specialty software for the colour correction and automated image aquisition of motorized microscopes.</li>
                        <li>Developed a peristaltic pump robot for sample polishing.</li>
                        <li>Gained laboratory experience using LECO, electron microscopy, and mass spectrometry.</li>
                        <li>Fixed a longstanding ToF-SIMS mass spectrometer system failure.</li>
                    </ul>
                </article>
                <article>
                    <h2>Research</h2>
                    <p><strong>Reinforcement Learning Summer Research Group</strong>, University of Toronto, Toronto, CA</p>
                    <ul>
                        <li>Worked under Professor Michael Guerzhoy on adjusting medication to minimize speech disfluency using reinforcement learning.</li>
                        <li>Team lead of the reinforcement learning team.</li>
                        <li>Co-wrote the reinforcement learning environment and patient simulation.</li>
                        <li>Accepted to the Machine Learning for Cognitive and Mental Health (ML4CMH) workshop at AAAI-24.</li>
                    </ul>
                </article>
                <article>
                    <h2>Skills</h2>
                    <ul>
                        <li><strong>Programming:</strong> Python, Java, C/C++, HTML, CSS, Javascript, Express, React, NodeJS, Flutter, Dart, React Native, Bash, Git, Rust</li>
                        <li><strong>Technologies:</strong> Blender, Linux/Unix Administration, nginx, Blockchain</li>
                        <li><strong>Scientific Tools:</strong> Mass Spectrometry, Electron Microscopy, Molecular Dynamics, Crystallographic Software, Tensorflow, Custom ML Environments</li>
                        <li><strong>Languages:</strong> English (native), Greek (native), German (intermediate, B2), French (intermediate, B1-2)</li>
                    </ul>
                </article>
                <article>
                    <h2>Community Service</h2>
                    <p><strong>Coordinator / Volunteer</strong> Chess In The Library, London Public Library, London, Ontario, CA</p>
                    <ul>
                        <li>Organized a city-wide chess tournament for over 100 children, providing many with their first
tournament experience.</li>
                        <li>Instructed individuals ranging in age from 4-80 in the game of chess.</li>
                        <li>Collaborated with other coordinators and library staff to establish a program across three locations,
benefiting over 100 children.</li>
                    </ul>
                </article>
                <article>
                    <h2>Awards and Distinctions</h2>
                    <ul>
                        <li>OPhO Top 50 (2%, international)</li>
                        <li>Canadian Geographic Challenge National Champion</li>
                        <li>Top 3% in Avogadro Chemistry Contest (international)</li>
                    </ul>
                </article>
            </section>
        </div>
    );
}
 
export default Resume;
